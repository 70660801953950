export function ValidationErrorModel(error) {
  if (!error) return;
  if (error.message) this.message = error.message;
  this.originalError = error;

  if (
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length
  ) {
    this.message = error.response.data.message;
    this.veeValidErrors = {};
    error.response.data.errors.forEach(({ field, detail }) => {
      this.veeValidErrors[field] = [detail];
    });
  }

  this.clearError = label => {
    const key = Object.keys(this.map).find(k => this.map[k] === label);
    const newErrors = { ...this.veeValidErrors }; // can not mutate old as vue will not pick up change
    if (newErrors[key]) delete newErrors[key];

    this.veeValidErrors = newErrors;
  };

  this.setFieldMap = (constants, map = {}) => { // constants labels from constants file, map manual mappings of id to label
    // eslint-disable-next-line no-return-assign
    Object.keys(constants).forEach(k => map[k] = constants[k].label);

    // done here so they don't have to be on every other form
    map.primaryUser = 'Primary User';
    map.secondaryUser = 'Secondary User';
    map.businessOwner = 'Business Owner';
    map.technicalOwner = 'Technical Owner';
    map.lineOfBusiness = 'Line of Business';
    this.map = map;
  };
}
